import "./App.css";
import { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

function App() {
  const [copied, setCopied] = useState(false);

  return (
    <div className="App">
      <header className="App-header">
        <StrapLine>
          {copied ? "Copied! Looking forward 😄" : "Get in contact:"}
        </StrapLine>
        <ButtonContainer
          nohover
          copied={copied}
          onClick={() => {
            navigator.clipboard
              .writeText("cheng.david04@gmail.com")
              .then(() => setCopied(true));
          }}
        >
          <Email>cheng.david04@gmail.com</Email>
          <ButtonIcon icon={copied ? faCheck : faCopy} copied={copied} />
        </ButtonContainer>

        <ButtonsContainer>
          <a
            href="https://drive.google.com/file/d/1uJrMXZhF6BP7tZolGCSy3NvUDfM_SzSS/view?usp=sharing"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <ButtonContainer
              nohighlight
              border
              style={{ width: 200, justifyContent: "center" }}
            >
              <ButtonIcon icon={faFilePdf} copied={copied} />
              <ButtonBody>Resume</ButtonBody>
            </ButtonContainer>
          </a>

          <a
            href="https://www.linkedin.com/in/davidcheng-io/"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <ButtonContainer
              nohighlight
              border
              style={{ width: 200, justifyContent: "center" }}
            >
              <ButtonIcon icon={faLinkedin} copied={copied} />
              <ButtonBody>LinkedIn</ButtonBody>
            </ButtonContainer>
          </a>
        </ButtonsContainer>
      </header>
    </div>
  );
}

const StrapLine = styled.h4`
  margin: 0;
  color: #fafafa;
  line-height: 30px;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  transition: all 0.2s ease-in;
  height: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  padding: 8px 30px;
  transition: all 0.2s ease-in;
  color: ${(props) => {
    if (props.copied) {
      return "black";
    }
    return props.nohover ? "black" : "#fafafa";
  }};
  background-color: ${(props) => {
    if (props.copied) {
      return "#81d669";
    }
    return props.nohover ? "#fafafa" : "none";
  }};
  border-radius: 1000px;
  border: ${(props) => (props.border ? "2px solid" : "none")};

  &:hover {
    color: ${(props) => (props.nohighlight ? "white" : "black")};
    background-color: ${(props) => {
      if (props.nohighlight) {
        return "transparent";
      }
      return props.copied ? "#81d669" : "#fafafa";
    }};
    border-radius: 1000px;
    transform: scale(1.05);
  }

  &:hover ${ButtonIcon} {
    color: ${(props) => (props.nohighlight ? "white" : "black")};
  }
`;

const Email = styled.h3`
  margin: 0px 10px 0px 0px;
`;

const ButtonsContainer = styled.div`
  margin-top: 44px;
`;

const ButtonBody = styled.b`
  margin-left: 8px;
  font-size: 20px;
`;

export default App;
